import React, { Component } from "react";

import { SessionProvider } from "contexts";
import { AccessPortal } from "components";
import { Scrapper } from "scenes";

class App extends Component {
  render() {
    return (
      <SessionProvider>
        <AccessPortal>
          <Scrapper />
        </AccessPortal>
      </SessionProvider>
    );
  }
}

export default App;
