import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import download from "downloadjs";

import { SessionContext } from "contexts";
import { PaymentRequestForm, LogoutButton } from "components";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const styles = {
  paper: {
    margin: "12px",
    padding: "16px",
    flexBasis: "420px"
  },
  progressWrapper: {
    textAlign: "center",
    padding: "48px 0"
  }
};

const handleApiResponse = response => {
  if (!response.ok) {
    return response.json().then(
      json => {
        throw json.error;
      },
      () => {
        throw Error("Network error");
      }
    );
  }
  return response.text();
};

class Scrapper extends React.PureComponent {
  state = {
    submitLoading: false
  };

  onFormSubmit = paymentRequest => {
    const { sessionId, clearSession } = this.props;
    this.setState({
      submitLoading: true
    });
    fetch(`${API_ENDPOINT}/retrieve-payment-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/csv"
      },
      body: JSON.stringify({
        sessionId,
        ...paymentRequest
      })
    })
      .then(handleApiResponse)
      .then(fileContent => {
        download(fileContent, "payments.csv", "text/csv");
        this.setState({ submitLoading: false });
        clearSession();
      })
      .catch(clearSession);
  };

  render() {
    const { classes } = this.props;
    const { submitLoading } = this.state;

    return (
      <Paper className={classes.paper}>
        <LogoutButton />
        <PaymentRequestForm
          onSubmit={this.onFormSubmit}
          loading={submitLoading}
        />
      </Paper>
    );
  }
}

const ScrapperWrapper = props => (
  <SessionContext.Consumer>
    {({ sessionId, clearSession }) => (
      <Scrapper sessionId={sessionId} clearSession={clearSession} {...props} />
    )}
  </SessionContext.Consumer>
);

export default withStyles(styles)(ScrapperWrapper);
