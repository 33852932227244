import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import { SessionContext } from "contexts";

const { PUBLIC_URL } = process.env;

const styles = {
  root: {
    paddingTop: "100px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progressWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    margin: "8px 0"
  },
  error: {
    margin: "12px"
  }
};

class AccessPortalForm extends React.Component {
  state = {
    username: "",
    password: ""
  };

  onFormSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
    return false;
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { classes, loading } = this.props;
    const { username, password } = this.state;

    const disabled = !username || !password || loading;

    return (
      <form onSubmit={this.onFormSubmit} className={classes.form}>
        <TextField
          name="username"
          label="Nom d'utilisateur"
          type="text"
          value={username}
          required
          onChange={this.handleChange}
          className={classes.input}
        />
        <TextField
          name="password"
          label="Mot de passe"
          type="password"
          autoComplete="current-password"
          value={password}
          required
          onChange={this.handleChange}
          className={classes.input}
        />
        <Button
          type="submit"
          disabled={disabled}
          variant="contained"
          color="primary"
          className={classes.input}
        >
          Se connecter
          {loading && (
            <div className={classes.progressWrapper}>
              <CircularProgress size={24} />
            </div>
          )}
        </Button>
      </form>
    );
  }
}

const AccessPortal = ({ children, classes }) => (
  <SessionContext.Consumer>
    {({ sessionId, login, error, loading }) => {
      if (sessionId) {
        return children;
      }

      return (
        <div className={classes.root}>
          <img
            src={PUBLIC_URL + "/logos/192_bright.png"}
            alt="Qotto"
            height={192}
          />
          <AccessPortalForm login={login} loading={loading} classes={classes} />
          {error && (
            <Typography className={classes.error} variant="h6" color="error">
              {error}
            </Typography>
          )}
        </div>
      );
    }}
  </SessionContext.Consumer>
);

export default withStyles(styles)(AccessPortal);
