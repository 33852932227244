import React from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { SessionContext } from "contexts";

const LogoutButton = () => (
  <SessionContext.Consumer>
    {({ logout }) => (
      <Button onClick={logout}>
        <ArrowBackIcon /> Se déconnecter
      </Button>
    )}
  </SessionContext.Consumer>
);

export default LogoutButton;
