import React from "react";

const API_ENDPOINT = ""; //process.env.REACT_APP_API_ENDPOINT;

const SessionContext = React.createContext();

export default SessionContext;

const formatError = error => {
  switch (error.message || String(error)) {
    case "Service unavailable":
      return "Le service est en cours d'utilisation. Veuillez réessayer plus tard.";
    case "Unknown internal error":
      return "Il y a probablement un problème coté MTN. Veuillez contacter un développeur Qotto.";
    case "Wrong credentials":
      return "Les identifiants sont invalides.";
    case "Invalid flash code":
      return "Le code flash est invalide.";
    case "Network error":
      return "Une erreur réseau est survenue. Veuillez contacter un développeur Qotto.";
    case "Failed to fetch":
      return "Une erreur réseau est survenue. Veuillez vérifier votre connexion internet.";
    default:
      return String(error);
  }
};

const handleApiResponse = response =>
  response.json().then(
    json => {
      if (!response.ok) {
        throw json.error;
      }
      return json;
    },
    () => {
      throw Error("Network error");
    }
  );

class SessionProvider extends React.Component {
  constructor(props) {
    super(props);

    const sessionId = sessionStorage.getItem("sessionId");

    this.state = {
      sessionId,
      login: this.login,
      logout: this.logout,
      clearSession: this.clearSession,
      loading: false,
      error: null
    };
  }

  login = async (username, password) => {
    this.setState({ loading: true, sessionId: null, error: null });
    try {
      const { sessionId } = await fetch(`${API_ENDPOINT}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          username,
          password
        })
      }).then(handleApiResponse);
      sessionStorage.setItem("sessionId", sessionId);
      this.setState({
        loading: false,
        sessionId
      });
    } catch (error) {
      this.clearSession(error);
    }
  };

  clearSession = (error = null) => {
    this.setState({
      sessionId: null,
      error: error && formatError(error),
      loading: false
    });
    sessionStorage.removeItem("sessionId");
  };

  logout = async () => {
    const { sessionId } = this.state;
    this.setState({
      sessionId: null,
      loading: true
    });
    sessionStorage.removeItem("sessionId");
    try {
      await fetch(`${API_ENDPOINT}/session`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          sessionId
        })
      }).then(handleApiResponse);
      this.setState({ loading: false });
    } catch (error) {
      this.clearSession(error);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <SessionContext.Provider value={this.state}>
        {children}
      </SessionContext.Provider>
    );
  }
}

export { SessionProvider };
