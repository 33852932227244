import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = {
  form: {
    display: "flex",
    flexDirection: "column"
  },
  progressWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    margin: "8px",
    flex: 1
  }
};

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const inputToDate = datetimeString => {
  if (!datetimeString) return null;
  return new Date(datetimeString).toISOString();
};

const dateToInput = date => {
  const tzDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return tzDate.toISOString().substring(0, 19);
};

class PaymentRequestForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const now = new Date();
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(now.getDate() - 2);

    this.state = {
      flashCode: "",
      nbPayments: "1000",
      minDate: dateToInput(twoDaysAgo),
      maxDate: dateToInput(now)
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    const { flashCode, nbPayments, minDate, maxDate } = this.state;
    this.props.onSubmit({
      flashCode,
      nbPayments: parseInt(nbPayments) || null,
      minDate: inputToDate(minDate),
      maxDate: inputToDate(maxDate)
    });
    return false;
  };

  render() {
    const { classes, loading } = this.props;
    const { flashCode, nbPayments, minDate, maxDate } = this.state;

    const disabled = !flashCode || loading;

    return (
      <form onSubmit={this.onSubmit} className={classes.form}>
        <TextField
          name="minDate"
          required
          label={`date du paiement le plus ancien (heure de ${currentTimezone})`}
          type="datetime-local"
          value={minDate || ""}
          onChange={this.handleChange}
          className={classes.input}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="maxDate"
          required
          label={`date du paiement le plus récent (heure de ${currentTimezone})`}
          type="datetime-local"
          value={maxDate || ""}
          onChange={this.handleChange}
          className={classes.input}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="nbPayments"
          required
          label="Nombre maximum de paiements à partir du plus récent"
          type="number"
          inputProps={{
            min: 1,
            max: 10000,
            step: 1
          }}
          value={nbPayments}
          onChange={this.handleChange}
          className={classes.input}
        />
        <TextField
          name="flashCode"
          label="Code flash"
          type="text"
          autoComplete="off"
          value={flashCode}
          required
          onChange={this.handleChange}
          className={classes.input}
        />
        <Button
          type="submit"
          disabled={disabled}
          variant="contained"
          color="primary"
          className={classes.input}
        >
          <CloudDownloadIcon />
          &nbsp;Télécharger
          {loading && (
            <div className={classes.progressWrapper}>
              <CircularProgress size={24} />
            </div>
          )}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(PaymentRequestForm);
